import React from 'react';
import * as Sentry from '@sentry/browser';

export default class Error extends React.Component {
  componentDidMount() {

  }

  static getInitialProps({ res, err }) {
    if (err) {
      Sentry.captureException(err);
    }
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;
    return { statusCode };
  }

  render() {
    return (
      <p>
        {this.props.statusCode
          ? `An error ${this.props.statusCode} occurred on server`
          : 'An error occurred on client'}
      </p>
    );
  }
}
